

const BlogContent = [

    {
        images: '01',
        title: 'Navigating Global Trade: Import-Export Strategies for 2024',
        category: 'Global Trade',
        bloglink: '/navigating-global-trade'
    },

    {
        images: '02',
        title: 'Why Your Business Needs Custom Software',
        category: 'Business Software',
        bloglink: '/why-your-business-needs-custom-software'
    },
    {
        images: '03',
        title: 'Boost Your Brand',
        category: 'Brand Reach',
        bloglink: '/boost-your-brand'
    },
]

export default BlogContent;